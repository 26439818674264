import { css, jsx } from '@emotion/react'
import { Box } from './chakra/Box'

const styles = {
  container: maxWidth => css`
    max-width: ${maxWidth}px;
    margin: auto;
  `,
  padding: ['15px', '25px'],
}

function MaxWidth({ css: propsCss = '', className = '', children, maxWidth = 1026, ...props }) {
  return (
    <Box
      {...props}
      css={styles.container(maxWidth)}
      className={`${propsCss} ${className}`}
      pr={styles.padding}
      pl={styles.padding}
    >
      {children}
    </Box>
  )
}

export default MaxWidth
