import { forwardRef } from 'react'
import { Heading as ChakraHeading } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'

// Prop           CSS Property    Theme Key      Ej
// fontFamily     font-family     fonts
// fontSize       font-size       fontSizes      md
// fontWeight     font-weight     fontWeights    bolder
// lineHeight     line-height     lineHeights
// letterSpacing  letter-spacing  letterSpacings
// color          color           colors         red.100
// textAlign      text-align      none
// fontStyle      font-style      none
// textTransform  text-transform  none
// textDecoration text-decoration none
// textShadow     text-shadow     shadows

// https://chakra-ui.com/docs/typography/heading

const styles = props => css`
  display: ${props.block ? 'block' : 'inline'};
`

export const Heading = forwardRef(({ block, css: propsCss = '', className = '', ...props }, ref) => {
  return (
    <ChakraHeading ref={ref} {...props} className={`${propsCss} ${className}`} css={styles({ block })}>
      {props.children}
    </ChakraHeading>
  )
})
