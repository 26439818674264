import { css, jsx } from '@emotion/react'
import { useCallback, useRef, Fragment } from 'react'
import { rgba } from 'polished'
import { useColorMode, Fade } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { publicRoadmapUrl } from '../../constants'
import { ChevronDown } from '../chakra/icons/ChevronDown'
import useResponsive from '../../utils/hooks/useResponsive'
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider } from '../chakra/Menu'
import MaxWidth from '../MaxWidth'
import theme from '../../constants/theme'
import Logo from '../Logo'
import Div from '../Div'
import DarkModeToggle from '../DarkModeToggle'
import HorizontalSpace from '../HorizontalSpace'
import { Button } from '../chakra/Button'
import scrollToView from '../../utils/scrollToView'
import ImgFallbackProgress from '../ImgFallbackProgress'
import UserProfileImg from '../UserProfileImg'
import HeaderLoadingBlocks from './HeaderLoadingBlocks'
import Portal from '../Portal'
import useEventListener from '../../utils/hooks/useEventListener'
import { useIsLoggedIn, useShowAccented, useUser } from '../../dataHooks'

const styles = {
  header: (colorModeInverse, background) => css`
    transition: all 200ms;
    position: sticky;
    top: 0;
    z-index: 99;
    width: 100%;
    background: inherit;
    @supports not ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      background: ${rgba(background, 0.9)};
    }
    &.scroll {
      box-shadow: 0 3px 3px ${rgba(theme.colors.background[colorModeInverse], 0.02)};
      border-bottom: 1px solid ${rgba(theme.colors.background[colorModeInverse], 0.08)};
      backdrop-filter: saturate(180%) blur(15px);
    }
  `,
  headerContent: css`
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `,
  darkModeToggle: (darkModeToggleTransform, darkModeToggleMarginL) => css`
    transition: all ${theme.transition.duration.normalSlow};
    transform: ${darkModeToggleTransform};
    margin-left: ${darkModeToggleMarginL};
    order: 1;
  `,
  chevronDown: isOpen => css`
    transform: rotate(${isOpen ? '180deg' : '0deg'});
    transition: transform ${theme.transition.duration.normalSlow};
    width: 19px;
    height: 19px;
  `,
  actionButtonMl: ['12px', '18px'],
  picture: css`
    border-radius: 100%;
  `,
  menuItem: css`
    &:active {
      background: ${theme.colors.accented.base};
    }
  `,
}

const scrollHeight = 30

function Header({ containerRef }) {
  const showHeaderMenu = useResponsive(true, true, false)
  const { isLoggedIn, loadingSession } = useIsLoggedIn()
  const router = useRouter()
  const { pathname } = router
  const showLogin = !isLoggedIn
  const { colorMode } = useColorMode()
  const colorModeInverse = (colorMode === 'dark' && 'light') || (colorMode === 'light' && 'dark')
  const headerRef = useRef()
  const logoSize = useResponsive(20, 20.5)
  const buttonSize = useResponsive('xs', 'sm', 'md')
  const primaryButtonSize = useResponsive('xs', 'sm')
  const darkModeToggleTransform = useResponsive('scale(.75)', 'scale(.85)')
  const darkModeToggleMarginL = useResponsive('0px', '0px', '12px')
  const pictureSize = useResponsive('22px', '24px')
  const buttonTextColor = useShowAccented({
    accented: colorMode === 'dark' ? theme.colors.text.accentedGray : '',
    normal: '',
  })
  const background = useShowAccented({
    accented: colorMode === 'dark' ? theme.colors.background.richBlack : theme.colors.background.light,
    normal: theme.colors.background[colorMode],
  })
  const { user } = useUser()
  const handleScroll = useCallback(() => {
    const scrollEl = containerRef?.current || {}
    const headerRefEl = headerRef?.current
    const containsClass = headerRefEl?.classList?.contains('scroll')
    const scroll = scrollEl.scrollTop / 10000

    // Note: This adds bg img effect
    if (scroll < 0.2) {
      document.documentElement.style.setProperty('--scroll', scroll)
    }

    if (scrollEl.scrollTop > scrollHeight && !containsClass) {
      headerRefEl?.classList?.add('scroll')
      return
    }
    if (scrollEl.scrollTop < scrollHeight && containsClass) {
      headerRefEl?.classList?.remove('scroll')
    }
  }, [])
  useEventListener('scroll', handleScroll, containerRef?.current, 20)

  const onMenuItemClicked = useCallback(
    menuType => async () => {
      if (pathname !== '/') {
        await router.push('/')
        return scrollToView(menuType)
      }
      scrollToView(menuType)
    },
    [router]
  )

  return (
    <header css={styles.header(colorModeInverse, background)} ref={headerRef}>
      <MaxWidth>
        <div css={styles.headerContent}>
          <Button
            reset
            aria-label="logo button"
            onClick={() => {
              if (pathname !== '/') return router.push('/')
              window.scroll({ top: 0, behavior: 'smooth' })
            }}
            css={css`
              position: relative;
            `}
          >
            <Logo size={logoSize} />
          </Button>
          {loadingSession && <HeaderLoadingBlocks />}
          <Fade in={!loadingSession}>
            <Div flex centerH>
              {showHeaderMenu ? (
                <Menu isLazy autoSelect={false}>
                  {({ isOpen }) => (
                    <Fragment>
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDown css={styles.chevronDown(isOpen)} />}
                        colorScheme="accentedButton"
                        lightMode
                        textTransform="uppercase"
                        size="sm"
                        order={2}
                        ml={styles.actionButtonMl}
                        aria-label="actions button"
                      >
                        Actions
                      </MenuButton>
                      <Portal>
                        <MenuList>
                          {showLogin ? (
                            <Fragment>
                              <MenuItem css={styles.menuItem} fontWeight="semibold" to="/login">
                                Sign up free
                              </MenuItem>
                              <MenuItem css={styles.menuItem} fontWeight="semibold" to="/login">
                                Login
                              </MenuItem>
                            </Fragment>
                          ) : (
                            <MenuItem css={styles.menuItem} fontWeight="semibold" to="/dashboard">
                              <UserProfileImg
                                imageUrl={user?.image}
                                email={user?.email}
                                width={pictureSize}
                                height={pictureSize}
                                css={styles.picture}
                                fallback={<ImgFallbackProgress size={pictureSize} radius="100%" />}
                              />
                              <HorizontalSpace size={1.5} />
                              My Bookmarks
                            </MenuItem>
                          )}

                          <MenuDivider />
                          <MenuItem css={styles.menuItem} onClick={onMenuItemClicked('Features')}>
                            Features
                          </MenuItem>
                          <MenuItem css={styles.menuItem} onClick={onMenuItemClicked('Pricing')}>
                            Pricing
                          </MenuItem>
                          <MenuItem css={styles.menuItem} onClick={onMenuItemClicked('Reviews')}>
                            Reviews
                          </MenuItem>
                          <MenuItem css={styles.menuItem} to={publicRoadmapUrl} isExternal>
                            What's next
                          </MenuItem>
                        </MenuList>
                      </Portal>
                    </Fragment>
                  )}
                </Menu>
              ) : (
                <Fragment>
                  <Button
                    variant="link"
                    size={buttonSize}
                    color={buttonTextColor}
                    onClick={onMenuItemClicked('Features')}
                    aria-label="Features"
                  >
                    Features
                  </Button>
                  <HorizontalSpace space={2} />
                  <Button
                    variant="link"
                    size={buttonSize}
                    color={buttonTextColor}
                    onClick={onMenuItemClicked('Pricing')}
                    aria-label="Pricing"
                  >
                    Pricing
                  </Button>
                  <HorizontalSpace space={2} />
                  <Button
                    variant="link"
                    size={buttonSize}
                    color={buttonTextColor}
                    onClick={onMenuItemClicked('Reviews')}
                    aria-label="Reviews"
                  >
                    Reviews
                  </Button>
                  <HorizontalSpace space={2} />
                  <Button
                    variant="link"
                    size={buttonSize}
                    color={buttonTextColor}
                    to={publicRoadmapUrl}
                    isExternal
                    aria-label="What's next"
                  >
                    What's next
                  </Button>
                  <HorizontalSpace space={2} />
                  {showLogin ? (
                    <Fragment>
                      <Button variant="link" size={buttonSize} color={buttonTextColor} to="/login" aria-label="Login">
                        Login
                      </Button>
                      <HorizontalSpace space={2} />
                      <Button
                        colorScheme="accentedButton"
                        lightMode
                        textTransform="uppercase"
                        size={primaryButtonSize}
                        to="/login"
                        aria-label="Sign up free"
                      >
                        Sign up free
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      colorScheme="accentedButton"
                      lightMode
                      size={primaryButtonSize}
                      textTransform="uppercase"
                      to="/dashboard"
                      aria-label="My Bookmarks"
                    >
                      <UserProfileImg
                        imageUrl={user?.image}
                        email={user?.email}
                        width={pictureSize}
                        height={pictureSize}
                        css={styles.picture}
                        fallback={<ImgFallbackProgress size={pictureSize} radius="100%" />}
                      />
                      <HorizontalSpace size={1.5} />
                      My Bookmarks
                    </Button>
                  )}
                </Fragment>
              )}
              <DarkModeToggle css={styles.darkModeToggle(darkModeToggleTransform, darkModeToggleMarginL)} />
            </Div>
          </Fade>
        </div>
      </MaxWidth>
    </header>
  )
}

export default Header
