import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Link } from '../chakra/Link'
import Div from '../Div'
import theme from '../../constants/theme'
import VerticalSpace from '../VerticalSpace'
import Logo from '../Logo'
import useResponsive from '../../utils/hooks/useResponsive'
import { Text } from '../chakra/Text'
import { ChevronUp } from '../chakra/icons/ChevronUp'
import { Button } from '../chakra/Button'
import { hiGuardoEmail, publicRoadmapUrl, twitterGuardo } from '../../constants'
import { analyticsTrack } from '../../utils/analytics'
import { useShowAccented } from '../../dataHooks'

const styles = {
  chevronButton: css`
    padding: 1px;
    border-radius: 3px;
    &:focus {
      box-shadow: ${theme.shadows.outline};
      transition: box-shadow ${theme.transition.duration.normalSlow};
    }
  `,
  chevronLogo: moveChevronUp => css`
    width: 30px;
    height: 30px;
    ${moveChevronUp && 'position: relative; bottom: 140px'}
    &:hover {
      color: ${theme.colors.accented.base};
    }
  `,
  columnsContainer: columnWidth => css`
    display: grid;
    grid-template-columns: repeat(${columnWidth}, auto);
    grid-row-gap: 20px;
  `,
  itemFooterEl: itemMarginRight => css`
    margin-right: ${itemMarginRight};
  `,
  title: css`
    margin-bottom: 12px;
  `,
  link: css`
    margin-bottom: 4px;
  `,
  titleFontSize: ['16px', '17px'],
  linkFontSize: ['14px', '15px'],
  logoTextFontSize: ['13px', '14px'],
  logoWrapper: css`
    margin-right: 20px;
  `,
}

const year = new Date().getFullYear()
const list = [
  {
    title: 'Contact',
    links: [
      { text: 'Twitter', href: twitterGuardo, isExternal: true },
      { text: 'Email', href: `mailto:${hiGuardoEmail}?subject=Contact`, isExternal: true },
    ],
  },
  {
    title: 'Product',
    links: [
      { text: 'About', href: '/about' },
      { text: 'Support', href: `mailto:${hiGuardoEmail}?subject=Support`, isExternal: true },
    ],
  },
  {
    title: "What's next",
    links: [
      {
        text: 'Roadmap',
        href: publicRoadmapUrl,
        isExternal: true,
        onClick: () => analyticsTrack('roadmap'),
      },
      { text: 'Feature request', href: `mailto:${hiGuardoEmail}?subject=Feature request`, isExternal: true },
    ],
  },
  {
    title: 'Legal',
    links: [
      { text: 'Terms of service', href: '/terms-of-service' },
      { text: 'Privacy Policy', href: '/privacy-policy' },
    ],
  },
]

const scrollTop = scrollEl =>
  scrollEl?.scrollTo({
    top: 0,
    behavior: 'smooth',
  })

function FooterEl({ title, links, itemMarginRight, colorTitle, colorList }) {
  return (
    <Div flex column css={styles.itemFooterEl(itemMarginRight)}>
      <Text css={styles.title} fontWeight="semibold" fontSize={styles.titleFontSize} color={colorTitle}>
        {title}
      </Text>
      {links.map(link => (
        <Link
          key={link.href}
          href={link.href}
          isExternal={link.isExternal}
          css={styles.link}
          fontSize={styles.linkFontSize}
          onClick={() => link.onClick && link.onClick()}
          color={colorList}
          colorScheme="accented"
        >
          {link.text}
        </Link>
      ))}
    </Div>
  )
}

function Footer({ containerRef }) {
  const { colorMode } = useColorMode()
  const scrollEl = containerRef?.current || {}
  const logoSize = useResponsive(6.7, 7, 7.5, 13)
  const columnWidth = useResponsive(1, 2, 4)
  const itemMarginRight = useResponsive('20px', '25px', '26px', '50px')
  const columnType = useResponsive({ column: true }, { row: 'true' })
  const moveChevronUp = useResponsive(true, false, false)
  const colorAccented = useShowAccented({
    accented: colorMode === 'dark' ? '#fff' : theme.colors.secondary.base,
    normal: '',
  })
  const colorLight = useShowAccented({
    accented: colorMode === 'dark' ? theme.colors.text.accentedGray : theme.colors.secondary.base,
    normal: '',
  })

  return (
    <footer>
      <VerticalSpace space={3} />
      <Div flex {...columnType} spaceBetween>
        <Div flex column css={styles.logoWrapper}>
          <Logo size={logoSize} />
          <VerticalSpace />
          <Text block fontSize={styles.logoTextFontSize}>
            © {year} Backtoit
          </Text>
          <Text block fontSize={styles.logoTextFontSize}>
            All rights reserved
          </Text>
        </Div>
        <Div flex column>
          <VerticalSpace space={5.5} />
          <Div flex spaceBetween>
            <div css={styles.columnsContainer(columnWidth)}>
              {list.map(item => (
                <FooterEl
                  key={item.title}
                  {...item}
                  itemMarginRight={itemMarginRight}
                  colorTitle={colorAccented}
                  colorList={colorLight}
                />
              ))}
            </div>
            <div>
              <Button reset onClick={() => scrollTop(scrollEl)} aria-label="scroll up" css={styles.chevronButton}>
                <ChevronUp css={styles.chevronLogo(moveChevronUp)} color={colorAccented} />
              </Button>
            </div>
          </Div>
        </Div>
      </Div>
      <VerticalSpace space={6} />
    </footer>
  )
}

export default Footer
