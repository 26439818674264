import { css, jsx } from '@emotion/react'
import { memo } from 'react'
import { Skeleton } from '../chakra/Skeleton'
import Div from '../Div'

const styles = {
  container: css`
    .header-loading-blocks-large,
    header-loading-blocks-small,
    header-loading-blocks-main-button {
      pointer-events: none;
    }
  `,
}

function HeaderLoadingBlocks() {
  return (
    <Div flex centerV position="absolute" right={0} css={styles.container}>
      {/* Show on desktop */}
      <>
        <Skeleton height="15px" width="76px" borderRadius="0.375rem" className="header-loading-blocks-large" />
        <Skeleton
          height="15px"
          width="76px"
          borderRadius="0.375rem"
          ml="10px"
          className="header-loading-blocks-large"
        />
        <Skeleton
          height="15px"
          width="76px"
          borderRadius="0.375rem"
          ml="10px"
          className="header-loading-blocks-large"
        />
        <Skeleton
          height="15px"
          width="76px"
          borderRadius="0.375rem"
          ml="10px"
          className="header-loading-blocks-large"
        />
      </>
      {/* Show on mobile */}
      <Skeleton height="26px" width="43px" borderRadius="9999px" className="header-loading-blocks-small" />
      <Skeleton height="32px" borderRadius="0.375rem" ml="15px" className="header-loading-blocks-main-button" />
      {/* Show on desktop */}
      <Skeleton height="25px" width="43px" borderRadius="9999px" ml="10px" className="header-loading-blocks-large" />
    </Div>
  )
}

export default memo(HeaderLoadingBlocks)
